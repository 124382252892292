.app__footer {
    flex: 1;
    width: 100%;
    flex-direction: column;
  
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
}
  
.app__footer-cards {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 4em 2rem 2rem;

  .app__footer-card {
    min-width: 290px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fef4f5;

    transition: all 0.3s ease-in-out;

    img {
      width: 40px;
      height: 40px;
      margin: 0 0.7rem;
    }

    p {
      font-weight: 500;
    }
    a {
      text-decoration: none;
      font-weight: 500;
    }
    &:hover {
      box-shadow: 0 0 25px #fef4f5;
    }

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.app__footer-cards .app__footer-card:last-child {
  background-color: #fef4f5;
}

.app__footer-cards .app__footer-card:last-child:hover {
  box-shadow: 0 0 25px #f2f7fb;
}

.app__footer-form {
  width: 60%;
  flex-direction: column;
  margin: 1rem 2rem;

  div {
    width: 100%;

    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--primary-color);

    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: #fef4f5;

      font-family: var(--font-base);
      color: var(--secondary-color);
      outline: none;
    }

    textarea {
      height: 170px;
    }

    &:hover {
      box-shadow: 0 0 25px var(--primary-color);
    }
  }

  // button {
  //   padding: 1rem 2rem;
  //   border-radius: 10px;
  //   //border: none;
  //   //background-color: var(--secondary-color);
  //   background-color: var(--neon-bg);
  //   color:var(--neon-color);
  //   border: var(--neon-color) 0.125em solid;
  //   font-size: 1.5rem;
  //   font-weight: 900;
  //   text-shadow: 
  //     0 0 0.125em #{'hsl(0 0% 100% / 30%)'},
  //     0 0 0.45em currentColor;
  //   box-shadow: 
  //     inset 0 0 0.5em 0 var(--neon-color),
  //     0 0 0.5em 0 var(--neon-color);

  //   //color: var(--white-color);
  //   outline: none;
  //   margin: 1.7rem 0 0 0;
  //   font-family: var(--font-base);

  //   transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  //   cursor: pointer;
  //   position: relative;

  //   // &:hover {
  //   //   background-color: #2430af;
  //   // }

  //   &:hover,
  //   &:focus {
  //     color: var(--clr-bg);
  //     text-shadow: none;
  //   }

  //   &:hover::before,
  //   &:focus::before {
  //     opacity: 1;
  //   }
  //   &:hover::after,
  //   &:focus::after {
  //     opacity: 1;
  //   }

  //   &:before {
  //     content: '';
  //     position:absolute;
  //     background:red;
  //     top: 120%;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;

  //     transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
  //     filter: blur(1em);
  //     opacity: 0.7;
  //   }

  //   &:after {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     bottom: 0;
  //     left: 0;
  //     right: 0;
  //     box-shadow: 0 0 2em 0.5em var(--clr-neon);
  //     opacity: 0;
  //     background-color: var(--clr-neon);
  //     z-index: -1;
  //     transition: opacity 100ms linear;
  //   }
  // }

  .neon-button {
    z-index: 3;
    font-size: 1.5rem;
    padding: 1rem 2rem;
    margin: 2.5rem 0 0 0;
    font-weight: 600;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    color: var(--clr-neon);
    border: var(--clr-neon) 0.125em solid;
    background: black;
    padding: 0.25em 1em;
    border-radius: 0.25em;
    text-shadow: 0 0 0.125em #{'hsl(0 0% 100% / 30%)'}, 0 0 0.45em currentColor;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon), 0 0 0.5em 0 var(--clr-neon);
    position: relative;
    transition: background-color 100ms linear;

    &:before {
      pointer-events: none;
      content: '';
      position: absolute;
      background: var(--clr-neon);
      top: 120%;
      left: 0;
      width: 100%;
      height: 100%;
      
      transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
      filter: blur(1em);
      opacity: 0.7;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 2em 0.5em var(--clr-neon);
      opacity: 0;
      background: var(--clr-neon);
      z-index: -1;
      transition: opacity 100ms linear;
    }

    &:hover,&:focus {
      color: var(--clr-bg);
      text-shadow: none;
    }

    &:hover::before,
    &:focus::before {
      opacity: 1;
    }

    &:hover::after,
    &:focus::after {
      opacity: 1;
    }

    
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}

.magnetic-button {
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;
  z-index: 1;
  touch-action: none;
  
  span {
    display: inline-block;
  }
  
  &--hover {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.button-1 {
  cursor: default;
  background: #075ab3;
  color: white;
  border-radius: 30px;
  border: none;
  padding: 20px 40px;
}
